import React from 'react'
import PropTypes from 'prop-types';
import classes from './Button.module.css';

const Button = (props) => {
    const classesArray = [
        classes.Button,
        ...props.styleClasses.map(styleClass => classes[styleClass])
    ];

    return (
        <button
            className={classesArray.join(' ')}
            disabled={props.disabled}
            onClick={props.onClickHandler}
        >{props.children}</button>
    )
};

Button.propTypes = {
    styleClasses: PropTypes.arrayOf(PropTypes.oneOf(["big", "small", "margin", "maxWidth"])),
    disabled: PropTypes.bool,
    onClickHandler: PropTypes.func,
}

Button.defaultProps = {
    styleClasses: ["big"],
    disabled: false
}

export default Button;