import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import classes from './App.module.css';

import Spinner from './components/UI/Spinner/Spinner';
import Toolbar from './components/Toolbar/Toolbar';
import LegalNotice from './components/LegalNotice/LegalNotice';
import Footer from './components/Footer/Footer'

const Landingpage = lazy(() => import('./components/Landingpage/Landingpage'));
const CatalogOrder = lazy(() => import('./components/CatalogOrder/CatalogOrder'));
const MeasurementOrder = lazy(() => import('./components/MeasurementOrder/MeasurementOrder'))

const App = (props) => {
  return (
    <div className={classes.App} >
      <Toolbar />

      <main className={classes.Content} >
        {props.hideContent ? null : (
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route path="/measurement" component={MeasurementOrder} />
              <Route path="/catalog" component={CatalogOrder} />
              <Route path="/" component={Landingpage} />
            </Switch>
            <LegalNotice />
          </Suspense>
        )}
      </main>

      <Footer />
    </div>
  )
}

App.propTypes = {
  hideContent: PropTypes.bool
}

App.defaultProps = {
  hideContent: false
}

export default App;
