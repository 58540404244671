import React from 'react';
import classes from './Footer.module.css';
import { highlightColor } from '../../Layout/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
    return (
        <div className={classes.Footer}>
            <div className={classes.Wrapper}>
                <h2>Kontakt</h2>
                <div className={classes.Divider} ></div>
                <div className={classes.ContactRow}>
                    <div className={classes.ContactIcon}>
                        <FontAwesomeIcon
                            icon="map-marker-alt"
                            color={highlightColor}
                            size="2x"
                        />
                    </div>
                    <p>
                        NECK Duschen e.K.<br />
                    Ringstraße 69<br />
                    D-63843 Niedernberg/Main
                </p>
                </div>
                <div className={classes.ContactRow}>
                    <div className={classes.ContactIcon}>
                        <a href="tel:+490602840440" target="_new">
                            <FontAwesomeIcon
                                icon="phone-alt"
                                color={highlightColor}
                                size="2x"
                            />
                        </a>
                    </div>
                    <p>0 60 28/40 44 – 0</p>
                </div>
                <div className={classes.ContactRow}>
                    <div className={classes.ContactIcon}>
                        <a href="mailto:info@neck-duschen.de" target="_new">
                            <FontAwesomeIcon
                                icon="envelope"
                                color={highlightColor}
                                size="2x"
                            />
                        </a>
                    </div>
                    <p>info@neck-duschen.de</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;