import React from 'react';
import neckLogo from '../../assets/neck-logo-001.png';
import classes from './Logo.module.css';

const Logo = () => (
    <div className={classes.Logo} >
        <img src={neckLogo} alt="NECK Duschen und Duschkabinen für ihr Bad" />
    </div>
);

export default Logo;
