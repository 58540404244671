import React from 'react';
import classes from './LegalNotice.module.css';
import Button from '../UI/Button/Button';

const LegalNotice = () => (
    <div className={classes.LegalNotice} >
        <h2>Rechtliche Hinweise</h2>
        <p>
            Alle in der App erhobenen Daten werden von <strong>NECK Duschen</strong> <u>ausschließlich zur Bearbeitung der Kundenanfrage</u> verwendet und an keinen Dritten weiter gegeben.<br />
        </p>
        <div className={classes.LegalLinks}>
            <a href="https://www.neck-duschen.de/datenschutz/"
                target="_blank"
                rel="noreferrer"
                className={classes.ExternalLink}
            ><Button styleClasses={["small"]} >Datenschutz</Button></a>
            <a href="https://www.neck-duschen.de/impressum/"
                target="_blank"
                rel="noreferrer"
                className={classes.ExternalLink}
            ><Button styleClasses={["small"]} >Impressum</Button></a>
        </div>
    </div>
);

export default LegalNotice;