import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classes from './Toolbar.module.css';
import { highlightColor } from '../../Layout/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Logo from '../Logo/Logo';

const Toolbar = () => {
    return (
        <div className={classes.Toolbar} >
            <div className={classes.Wrapper} >
                <div className={classes.LogoContainer}>
                    <Logo />
                </div>
                <div className={classes.AppName}>
                    <h2>PARTNER App</h2>
                </div>
                <div className={classes.BackButton}>
                    {useLocation().pathname === '/' ? null : (
                        <NavLink to="/" exact>
                            <FontAwesomeIcon
                                icon="chevron-circle-left"
                                color={highlightColor}
                                size="2x"
                            />
                        </NavLink>
                    )}
                </div>
            </div>
        </div>
    );

};

export default Toolbar;